import { CourseLightModel, CourseModel, SessionLightModel, SessionModel } from '@24hbs/utils';
import { getShortedSlugFromFull, isShortSlug } from '$lib/utils/courses/slug';
import { CourseCategory, CourseCategoryStore } from '$lib/utils/courses/category-store';


export const isCodingAcademy = (course: CourseModel | CourseLightModel | SessionLightModel | SessionModel): boolean => {
    if(!course) return false;

    return CourseCategoryStore.getCourseCategoryByCategoryName(course.category) === CourseCategory.CodingAcademy;
}

export const getCodingAcademyProductUrl = (course: CourseModel | CourseLightModel | SessionLightModel | SessionModel): string => {
    return '/coding-academy/' + (isShortSlug(course.slug)?getShortedSlugFromFull(course.slug):course.slug);
}
